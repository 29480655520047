/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import dayjs from "dayjs";
import Layout from "../components/Layout";
import Post from "../components/Post";
import { filterPostByPublishedAt } from "../utils/helpers";

export default ({ data }) => {
  const posts = filterPostByPublishedAt(data.allSanityPost.edges, dayjs());
  return (
    <Layout>
      {posts.map(({ node }) => (
        <Post key={node.id} post={node} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { title: { ne: "About" } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          _rawBody
          publishedAt
          _rawFootnotes
        }
      }
    }
  }
`;
